import Menu from '../components/menu';

// import Flickity from 'flickity';
// import Overlay from '../util/overlay';
// import GMenu from "../components/g-menu";
// import Scroller from '../util/scroller';
// import ScrollMagic from 'scrollmagic';
// import objectFitImages from 'object-fit-images';

// import 'bootstrap/js/src/alert';
// import 'bootstrap/js/src/util';

function callbackVisible() {
  this.classList.add('is-visible');
}

function callbackSlideY() {
  this.classList.add('slide-y');
}

export default {
  init() {
    // new GMenu();
    // new SearchOverlay();
    // make alerts dismissable (newsletter signup forms, contact form)
    // $('.alert').alert();

    // this.productTiles = document.querySelectorAll(".product-tile");

    // this.elementsToFadeIn = document.querySelectorAll(".fade-in");
    console.log('init');
    new Menu();
  },
  finalize() {
    // objectFitImages('img.object-fit-cover');
    // var banner;
    // var overlays = document.querySelectorAll('.overlay');
    // var overlay_id;
    // var overlay;
    // if (overlays !== null) {
    //     for (var i = 0; i < overlays.length; i++) {
    //         overlay_id = overlays[i].id;
    //         overlay = new Overlay({
    //           trigger: document.querySelectorAll('.overlay__button--' + overlay_id),
    //           events: {
    //             beforeClose: function() {
    //               var videoIframe = document.getElementById(overlay_id+"iframe");
    //               // var iframeWindow = videoIframe.contentWindow ? videoIframe.contentWindow : videoIframe.contentDocument.defaultView;
    //               //     iframeWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               //jQuery("#"+overlay_id+"iframe")[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    //                   // videoIframe.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //               // var iframe = element.querySelector("iframe");
    //               var video = videoIframe.querySelector('video');
    //               if (videoIframe) {
    //                   var iframeSrc = videoIframe.src;
    //                   videoIframe.src = iframeSrc;
    //               }
    //               if (video) {
    //                 video.pause();
    //               }
    //             }
    //           }
    //         });
    //     }
    // }
    // ScrollMagic animations
    // var mobileMenu = document.getElementById("gn-menu-main");
    // var isMobile = !!(mobileMenu.offsetWidth || mobileMenu.offsetHeight || mobileMenu.getClientRects().length);
    // var dropShadowMultiplier = (isMobile) ? 10 : 15 ;
    // var scrollController = new ScrollMagic.Controller();
  },
};
