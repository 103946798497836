import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Menu {
  constructor() {
    this.state = {
      button: document.querySelector('.menu-button'),
      nav: document.querySelector('nav'),
      open: false,
    };

    if (this.state.button) {
      this.state.button.addEventListener('click', () => {
        // this.state.open = !this.state.open;
        this.onButtonClick();
      });
    }
  }

  onButtonClick() {
    this.state.open = !this.state.open;
    const { open, nav, button } = this.state;

    button.classList.toggle('is-open');
    nav.classList.toggle('is-visible');
    console.log('OPEN', open);
    if (!open) {
      enableBodyScroll(nav);
    } else {
      disableBodyScroll(nav);
    }
  }
}

export default Menu;
