// import "jquery";
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/util';
// import "babel-polyfill";
import Router from './util/Router';

// Routes
import common from './routes/common';
import home from './routes/home';

const routes = new Router({
  common,
  home,
});

function ready(func) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    func();
  } else {
    document.addEventListener('DOMContentLoaded', func);
  }
}

ready(function() {
  routes.loadEvents();
});
